import { InferGetStaticPropsType, NextPage } from 'next';

import {
  FooterDocument,
  FooterQuery,
  FooterQueryVariables,
  FormDocument,
  FormQuery,
  FormQueryVariables,
  NavigationDocument,
  NavigationQuery,
  NavigationQueryVariables,
  SineStackCardsSectionDocument,
  SineStackCardsSectionQuery,
  SineStackCardsSectionQueryVariables,
  SineStackHeroDocument,
  SineStackHeroQuery,
  SineStackHeroQueryVariables,
  SineStackImageFeatureDocument,
  SineStackImageFeatureQuery,
  SineStackImageFeatureQueryVariables,
  SineStackLargeImageDocument,
  SineStackLargeImageQuery,
  SineStackLargeImageQueryVariables,
  SineStackNumbersDocument,
  SineStackNumbersQuery,
  SineStackNumbersQueryVariables,
  SineStackSeoDocument,
  SineStackSeoQuery,
  SineStackSeoQueryVariables,
  SineStackTextImageSectionDocument,
  SineStackTextImageSectionQuery,
  SineStackTextImageSectionQueryVariables,
  SineStackTwoVideosGridDocument,
  SineStackTwoVideosGridQuery,
  SineStackTwoVideosGridQueryVariables,
  SineStackVideoFeatureDocument,
  SineStackVideoFeatureQuery,
  SineStackVideoFeatureQueryVariables,
} from '@/api';
import { addApolloState, initializeApollo } from '@/api/apolloClient';
import { SineStackPage } from '@/views/sineStack';

export const getStaticProps = async () => {
  const client = initializeApollo();

  //prefetch
  await client.query({ query: SineStackSeoDocument });
  await client.query({ query: SineStackHeroDocument });
  await client.query({ query: SineStackCardsSectionDocument });
  await client.query({ query: SineStackNumbersDocument });
  await client.query({ query: SineStackVideoFeatureDocument });
  await client.query({ query: SineStackImageFeatureDocument });
  await client.query({ query: SineStackTwoVideosGridDocument });
  await client.query({ query: SineStackTextImageSectionDocument });
  await client.query({ query: SineStackLargeImageDocument });
  await client.query({ query: FooterDocument });
  await client.query({ query: FormDocument });
  await client.query({ query: NavigationDocument });

  const { data: heroData } = await client.query<
    SineStackHeroQuery,
    SineStackHeroQueryVariables
  >({ query: SineStackHeroDocument });

  const { data: cardsData } = await client.query<
    SineStackCardsSectionQuery,
    SineStackCardsSectionQueryVariables
  >({
    query: SineStackCardsSectionDocument,
  });

  const { data: numbersData } = await client.query<
    SineStackNumbersQuery,
    SineStackNumbersQueryVariables
  >({
    query: SineStackNumbersDocument,
  });

  const { data: videoFeatureData } = await client.query<
    SineStackVideoFeatureQuery,
    SineStackVideoFeatureQueryVariables
  >({ query: SineStackVideoFeatureDocument });

  const { data: imageFeatureData } = await client.query<
    SineStackImageFeatureQuery,
    SineStackImageFeatureQueryVariables
  >({ query: SineStackImageFeatureDocument });

  const { data: textImageSectionData } = await client.query<
    SineStackTextImageSectionQuery,
    SineStackTextImageSectionQueryVariables
  >({
    query: SineStackTextImageSectionDocument,
  });

  const { data: twoVideosGridData } = await client.query<
    SineStackTwoVideosGridQuery,
    SineStackTwoVideosGridQueryVariables
  >({
    query: SineStackTwoVideosGridDocument,
  });

  const { data: largeImageSectionData } = await client.query<
    SineStackLargeImageQuery,
    SineStackLargeImageQueryVariables
  >({
    query: SineStackLargeImageDocument,
  });

  const { data: seoData } = await client.query<
    SineStackSeoQuery,
    SineStackSeoQueryVariables
  >({ query: SineStackSeoDocument });

  const { data: formData } = await client.query<FormQuery, FormQueryVariables>({
    query: FormDocument,
  });

  const { data: footerData } = await client.query<
    FooterQuery,
    FooterQueryVariables
  >({ query: FooterDocument });

  const { data: headerData } = await client.query<
    NavigationQuery,
    NavigationQueryVariables
  >({ query: NavigationDocument });

  return addApolloState(client, {
    props: {
      seoData,
      heroData,
      cardsData,
      numbersData,
      videoFeatureData,
      imageFeatureData,
      textImageSectionData,
      twoVideosGridData,
      largeImageSectionData,
      formData,
      footerData,
      headerData,
    },
  });
};

const SineStack: NextPage<InferGetStaticPropsType<typeof getStaticProps>> = ({
  seoData,
  heroData,
  cardsData,
  numbersData,
  videoFeatureData,
  imageFeatureData,
  textImageSectionData,
  twoVideosGridData,
  largeImageSectionData,
  formData,
  footerData,
  headerData,
}) => {
  return (
    <SineStackPage
      seo={seoData?.sineStack?.data?.attributes?.seo}
      hero={heroData?.sineStack?.data?.attributes?.heroImageSection}
      cards={cardsData?.sineStack?.data?.attributes?.cardsSection}
      numbers={numbersData?.sineStack?.data?.attributes?.numbersSection}
      videoFeature={videoFeatureData?.sineStack?.data?.attributes?.videoFeatureSection?.map(
        (item) => ({
          ...item,
        }),
      )}
      imageFeature={imageFeatureData?.sineStack?.data?.attributes?.imageFeatureSection?.map(
        (item) => ({
          ...item,
        }),
      )}
      textImageSection={
        textImageSectionData?.sineStack?.data?.attributes?.textImageSection
      }
      twoVideosGrid={
        twoVideosGridData?.sineStack?.data?.attributes?.twoVideosGrid
      }
      largeImageSection={
        largeImageSectionData?.sineStack?.data?.attributes?.largeImageSection
      }
      formData={formData}
      footerData={footerData}
      headerData={headerData}
    />
  );
};

export default SineStack;
