import {
  IconCardSection,
  IconCardSectionProps,
} from '@rimactechnology/rimac-ui-lib';

import { CardsSectionFragment } from '@/api';
import getCdlPublicId from '@/hooks/useCdlPublicId';

interface IconCardsProps {
  cards?: CardsSectionFragment | null;
  titleMaxWidth?: number;
}

export default function IconCards({ cards, titleMaxWidth }: IconCardsProps) {
  const iconCardsProps: IconCardSectionProps = {
    title: cards?.title || '',
    subtitle: cards?.subtitle || '',
    anchor: cards?.anchor || undefined,
    titleMaxWidth,
    cards: (cards?.iconCard || []).map((cards, index) => ({
      index: index || 1,
      title: cards?.title || '',
      icon: getCdlPublicId(cards?.icon?.data?.attributes?.url || ''),
      text: cards?.description || '',
    })),
  };

  return <IconCardSection {...iconCardsProps} />;
}
