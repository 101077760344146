import {
  imageCaseStudyType,
  LargeImageBackgroundType,
  LargeImageSection,
  LargeImageSectionProps,
  NumbersSection,
  NumbersSectionProps,
  SingleImageFeatureSection,
  SingleVideoFeatureSection,
  TextImageSection,
  TextImageSectionProps,
  TwoImagesFeatureSection,
  TwoVideosFeatureSection,
  TwoVideosHeadlineGrid,
  TwoVideosHeadlineGridProps,
  useCaseType,
} from '@rimactechnology/rimac-ui-lib';

import {
  CardsSectionFragment,
  FooterQuery,
  FormQuery,
  HeroImageSectionFragment,
  ImageFeatureSectionFragment,
  LargeImageSectionFragment,
  NavigationQuery,
  NumbersSectionFragment,
  SeoFragment,
  TextImageSectionFragment,
  TwoVideosGridFragment,
  VideoFeatureSectionFragment,
} from '@/api';
import { Layout } from '@/components/layout/Layout';
import { Seo } from '@/components/Seo';
import HeroImageSection from '@/components/shared/HeroImageSection';
import IconCards from '@/components/shared/IconCards';
import getCdlPublicId from '@/hooks/useCdlPublicId';

type Props = {
  seo?: SeoFragment | null;
  hero?: HeroImageSectionFragment | null;
  cards?: CardsSectionFragment | null;
  numbers?: NumbersSectionFragment | null;
  videoFeature?: VideoFeatureSectionFragment[] | null;
  imageFeature?: ImageFeatureSectionFragment[] | null;
  textImageSection?: TextImageSectionFragment | null;
  twoVideosGrid?: TwoVideosGridFragment | null;
  largeImageSection?: LargeImageSectionFragment | null;
  formData: FormQuery;
  footerData: FooterQuery;
  headerData: NavigationQuery;
};

export const SineStackPage = ({
  hero,
  cards,
  numbers,
  videoFeature,
  imageFeature,
  textImageSection,
  twoVideosGrid,
  largeImageSection,
  seo,
  formData,
  footerData,
  headerData,
}: Props) => {
  const numbersProps: NumbersSectionProps = {
    title: numbers?.title || '',
    cards: (numbers?.cards || []).map((card, index) => ({
      index,
      title: card?.title || '',
      value: card?.value || '',
      description: card?.description || '',
    })),
  };

  const videoFeatureSectionsProps = (videoFeature || []).map((feature) => {
    return {
      anchor: feature?.anchor || '',
      useCase: 'energy' as useCaseType,
      title: feature?.title || '',
      text: feature?.richText || [],
      video: (feature?.video || []).map((item) => {
        return {
          videoSrc: getCdlPublicId(item?.video?.data?.attributes?.url || ''),
          posterSrc: getCdlPublicId(item?.poster?.data?.attributes?.url || ''),
          posterAlt: item?.poster_alt || '',
          blurHash:
            item?.video?.data?.attributes?.blurhash ||
            'LAS?DV%M_3D%t7fQofj[~qM{4n-;',
          controls: false,
          width: 1920,
          height: (feature?.video?.length || 0) > 1 ? 1080 : 734,
          showOriginalSource: (feature?.video?.length || 0) <= 1,
        };
      }),
    };
  });

  const imageFeatureSectionsProps = (imageFeature || []).map((feature) => {
    return {
      anchor: feature?.anchor || '',
      useCase: 'energy' as useCaseType,
      title: feature?.title || '',
      text: feature?.richText || [],
      images: (feature?.image || []).map((item) => {
        return {
          imgSrc: getCdlPublicId(
            item?.img_desktop?.data?.attributes?.url || '',
          ),
          imgSrcMobile: getCdlPublicId(
            item?.img_mobile?.data?.attributes?.url || '',
          ),
          alt: item?.alt || '',
          blurhash: item?.img_desktop?.data?.attributes?.blurhash || '',
        };
      }),
    };
  });

  const textImageSectionProps: TextImageSectionProps = {
    useCase: 'energy' as useCaseType,
    anchor: textImageSection?.anchor || undefined,
    title: textImageSection?.title || '',
    text: textImageSection?.text,
    image: {
      imgSrc: getCdlPublicId(
        textImageSection?.image?.img_desktop?.data?.attributes?.url || '',
      ),
      imgSrcMobile: getCdlPublicId(
        textImageSection?.image?.img_mobile?.data?.attributes?.url || undefined,
      ),
      alt: textImageSection?.image?.alt || '',
      blurHash:
        textImageSection?.image?.img_desktop?.data?.attributes?.blurhash ||
        'LAS?DV%M_3D%t7fQofj[~qM{4n-;',
    },
    iconSpecs: (textImageSection?.iconSpec || []).map((iconSpec) => ({
      text: iconSpec?.text || '',
      icon: {
        imgSrc: getCdlPublicId(
          iconSpec?.icon?.img_desktop?.data?.attributes?.url || '',
        ),
        alt: iconSpec?.icon?.alt || '',
        blurhash: iconSpec?.icon?.img_desktop?.data?.attributes?.blurhash || '',
      },
    })),
  };

  const twoVideosGridProps: TwoVideosHeadlineGridProps = {
    title: twoVideosGrid?.title || '',
    cards: (twoVideosGrid?.videosGridItems || []).map((videosGridItem) => ({
      title: videosGridItem?.title || '',
      text: videosGridItem?.description || '',
      tagName: videosGridItem?.tag || '',
      tagType: (videosGridItem?.tagColor || 'default') as imageCaseStudyType,
      video: {
        videoSrc: getCdlPublicId(
          videosGridItem?.video?.video?.data?.attributes?.url || '',
        ),
        posterSrc: getCdlPublicId(
          videosGridItem?.video?.poster?.data?.attributes?.url || '',
        ),
        posterAlt: videosGridItem?.video?.poster_alt || '',
        blurHash:
          videosGridItem?.video?.poster?.data?.attributes?.blurhash ||
          'LAS?DV%M_3D%t7fQofj[~qM{4n-;',
        controls: false,
      },
    })),
  };

  const largeImageSectionProps: LargeImageSectionProps = {
    image: {
      imgSrc: getCdlPublicId(
        largeImageSection?.image?.img_desktop?.data?.attributes?.url || '',
      ),
      imgSrcMobile: getCdlPublicId(
        largeImageSection?.image?.img_mobile?.data?.attributes?.url ||
          undefined,
      ),
      alt: largeImageSection?.image?.alt || '',
      blurHash:
        largeImageSection?.image?.img_desktop?.data?.attributes?.blurhash ||
        '12312312',
    },
    bgColor: 'light-brown' as LargeImageBackgroundType,
    title: largeImageSection?.title || '',
    anchor: largeImageSection?.anchor || undefined,
    description: largeImageSection?.description || '',
    button: {
      useCase: 'energy' as useCaseType,
      label: largeImageSection?.button?.label || '',
      path:
        largeImageSection?.button?.file?.data?.attributes?.url ||
        largeImageSection?.button?.path ||
        '',
      openInNewTab: !!largeImageSection?.button?.openInNewTab,
    },
  };

  return (
    <Layout footerData={footerData} formData={formData} headerData={headerData}>
      <Seo seo={seo} />
      <HeroImageSection hero={hero} subtitleMaxWidth={540} />
      <IconCards cards={cards} titleMaxWidth={607} />
      <NumbersSection {...numbersProps} />
      {videoFeatureSectionsProps.map((item) => {
        if (item.video.length > 1 && item.text)
          return (
            <TwoVideosFeatureSection
              key={item.title}
              {...item}
              videoLeft={item.video[0]}
              videoRight={item.video[1]}
            />
          );
        return (
          <SingleVideoFeatureSection
            key={item.title}
            {...item}
            video={item.video[0]}
            titleMaxWidth={530}
          />
        );
      })}
      {imageFeatureSectionsProps.map((item) => {
        if (item.images.length > 1 && item.text)
          return (
            <TwoImagesFeatureSection
              key={item.title}
              {...item}
              imageLeft={item.images[0]}
              imageRight={item.images[1]}
            />
          );
        return (
          <SingleImageFeatureSection
            key={item.title}
            {...item}
            image={item.images[0]}
          />
        );
      })}
      <TextImageSection {...textImageSectionProps} />
      <TwoVideosHeadlineGrid {...twoVideosGridProps} />
      <LargeImageSection {...largeImageSectionProps} />
    </Layout>
  );
};
